import React from 'react';

const MaintenancePage: React.FC = () => {
    return (
        <div className='container' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div className="row">
                <div className="col-sm-12 text-center">
                    <h1>We are currently under maintenance</h1>
                    <p>We'll be back soon!</p>
                </div>
            </div>
        </div>
    );
};

export default MaintenancePage;
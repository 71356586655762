import './App.scss';
import MaintenancePage from './MaintenancePage';

const App = () => {
  return (
    <MaintenancePage />
  );
}

export default App;
